import {
  Autocomplete, Checkbox, FormControl, FormLabel, Popper, PopperProps, TextField,
} from '@mui/material'
import { RootState } from 'Store'
import terms from 'common/terms'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import {
  ReactElement, useCallback, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Entity } from 'reducers/Admin/Entity/types'
import { changePortfolio } from 'reducers/Admin/Portfolio/portfolio.reducer'
import { controllerLigne, getLignes } from 'reducers/Admin/Portfolio/portfolio.thunk'
import { PortfolioItem } from 'reducers/Admin/Portfolio/type'
import { controllerComplexes, searchComplexes } from 'reducers/Admin/Complexes/complexes.thunk'
import { controllerConstructionSites, getConstructionSites } from 'reducers/Admin/admin.thunk'
import { debounce } from 'lodash'
import portfolioService from 'services/portfolioService'
import ObjChip from 'pages/SupervisionPortfolios/Components/ObjChip'

import '../../../SupervisionPortfolios/Components/objChip.scss'

interface Props {
  portfolio: PortfolioItem
}

const PortfolioDetail = ({ portfolio }: Props): ReactElement => {
  const dispatch = useDispatch()
  const scrollToRef = useRef(null)
  const { entities } = useSelector((state: RootState) => state.entity)
  const { complexes } = useSelector((state: RootState) => state.complexes)
  const { robustestComplexes, lignes } = useSelector((state: RootState) => state.portfolio)
  const { allLignes } = useSelector((state: RootState) => state.map)
  const { constructionSites } = useSelector((state: RootState) => state.constructionSites)
  const [select, setSelect] = useState<{id: string, libelle: string}[]>([])
  const [currentAddId, setCurrentAddId] = useState<string>('')

  const CustomPopper = (props: PopperProps) => <Popper {...props} placement="bottom" />

  const debouncedSearchObj = useCallback(debounce((value: string) => {
    controllerLigne.abort()
    controllerComplexes.abort()
    controllerConstructionSites.abort()
    dispatch(getLignes(value))
    dispatch(searchComplexes(value))
    dispatch(getConstructionSites(value))
  }, 500), [])

  useEffect(() => {
    if (portfolio) {
      setSelect(portfolioService.getObjList(
        portfolio.batiments_voyageurs,
        portfolio.travaux,
        portfolio.lignes,
        robustestComplexes,
      ))
    }
  }, [portfolio])

  useEffect(() => {
    if (currentAddId && scrollToRef.current) {
      const ref = scrollToRef.current as HTMLElement
      ref.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [currentAddId])

  const dispatchSelectedObj = (values: {id: string, libelle: string}[]) => {
    setSelect(values)
    if (values.length) {
      const selectedComplexes = robustestComplexes.filter(
        complexe => values.map(value => value.id).includes(complexe.id),
      )
      const selectedConstructionSites = constructionSites.filter(
        constructionSite => values.map(value => value.id).includes(constructionSite.id),
      )
      const selectedLignes = allLignes ? allLignes.filter(
        ligne => values.map(value => value.id).includes(ligne.id),
      ) : []
      dispatch(changePortfolio({
        ...portfolio,
        lignes: selectedLignes.length ? selectedLignes : [],
        travaux: selectedConstructionSites.length ? selectedConstructionSites : [],
        batiments_voyageurs: selectedComplexes.length ? selectedComplexes : [],
      }))
    } else {
      dispatch(changePortfolio({
        ...portfolio,
        batiments_voyageurs: [],
        travaux: [],
        lignes: [],
      }))
    }
  }

  return (
    <div className="portfolio-form">
      <FormControl fullWidth>
        <FormLabel
          htmlFor="outlined-adornment-amount"
          required
        >
          {terms.Admin.portfolio.form.labelEntity}
        </FormLabel>
        <Autocomplete
          fullWidth
          disablePortal
          loading={false}
          options={entities}
          getOptionLabel={option => option.libelle}
          isOptionEqualToValue={(option, value) => option.libelle === value.libelle}
          noOptionsText={terms.Common.noResults}
          value={portfolio.entite}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              id="outlined-controlled"
              size="small"
            />
          )}
          onChange={(_, value) => dispatch(changePortfolio({ ...portfolio, entite: value as Entity }))}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel
          htmlFor="outlined-adornment-amount"
          required
        >
          {terms.Admin.portfolio.form.labelLibelle}
        </FormLabel>
        <TextField
          fullWidth
          id="outlined-controlled"
          size="small"
          value={portfolio.libelle}
          onChange={e => dispatch(changePortfolio({
            ...portfolio,
            libelle: e.target.value,
          }))}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel
          htmlFor="outlined-adornment-amount"
          required
        >
          {terms.Admin.portfolio.form.CLT}
        </FormLabel>
        <Autocomplete
          className="autocomplete-obj form-control form-control-sm"
          multiple
          disableCloseOnSelect
          sx={{ maxHeight: 250, overflowY: 'auto', overflowX: 'hidden' }}
          options={portfolioService.getObjList(
            complexes, constructionSites, lignes, robustestComplexes,
          ).sort((a, b) => a.libelle.localeCompare(b.libelle))}
          value={select.sort((a, b) => a.libelle.localeCompare(b.libelle))}
          onChange={(_: unknown, newValue) => {
            dispatchSelectedObj(newValue)
            setCurrentAddId(newValue[newValue.length - 1]?.id)
          }}
          getOptionLabel={option => `${option.id}.${option.libelle}`}
          renderOption={(props, option, { selected }) => {
            if (!selected) {
              return (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <p
                    className="object-jalon-composant-label"
                  >
                    {option.libelle}
                  </p>
                </li>
              )
            }
            return (
              <li {...props} style={{ padding: 0 }} />
            )
          }}
          renderInput={params => (
            <TextField
              {...params}
              className="vertical-display"
              fullWidth
            />
          )}
          renderTags={tabValue => (
            <div className="obj-list">
              {tabValue.map(tab => (
                <ObjChip
                  obj={tab}
                  isAdded={currentAddId === tab.id}
                  key={tab.id}
                  handleDelete={() => dispatchSelectedObj(select.filter(obj => obj.id !== tab.id))}
                  ref={currentAddId === tab.id ? scrollToRef : null}
                />
              ))}
            </div>
          )}
          isOptionEqualToValue={(opt, val) => opt.id === val.id}
          closeText=""
          openText=""
          noOptionsText={terms.Admin.noSearchText}
          onInputChange={(_, newInputValue) => debouncedSearchObj(newInputValue)}
          PopperComponent={CustomPopper}
        />
      </FormControl>
    </div>
  )
}

export default PortfolioDetail
