import { CancelRounded } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { forwardRef } from 'react'
import { AutocompleteValue } from 'reducers/types'
import './objChip.scss'

interface ObjChipProps {
  obj: AutocompleteValue,
  handleDelete: () => void,
  isAdded: boolean,
  readOnly?: boolean,
}

const ObjChip = forwardRef<HTMLDivElement, ObjChipProps>(({
  obj, handleDelete, isAdded, readOnly,
}: ObjChipProps, ref) => (
  <div
    className={`d-flex obj-chip ${isAdded ? 'highlight' : ''}`}
    key={obj.id}
    ref={ref}
  >
    {
      !readOnly && (
        <CancelRounded className="delete-icon" onClick={handleDelete} />
      )
    }
    <Typography className="obj-label">
      {obj.libelle}
    </Typography>
  </div>
))

ObjChip.displayName = 'ObjChip'

ObjChip.defaultProps = {
  readOnly: false,
}

export default ObjChip
