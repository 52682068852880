import {
  Autocomplete, Checkbox, FormControl, FormLabel, Popper, PopperProps, TextField,
} from '@mui/material'
import terms from 'common/terms'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { Entity } from 'reducers/Admin/Entity/types'
import { AutocompleteValue } from 'reducers/types'
import ObjChip from './ObjChip'

import './objChip.scss'

interface Props {
  portfolioEntity: Entity | null
  possibleEntities: Entity[]
  setPortfolioEntity: (entity: Entity | null) => void
  portfolioName: string
  setPortfolioName: (name: string) => void
  debouncedSearchObj: (value: string) => void
  setSelectedObj: (value: AutocompleteValue[]) => void,
  selectedObj: AutocompleteValue[]
  objList: AutocompleteValue[]
  disabled?: boolean
  isSupervisor?: boolean
}

const Form = ({
  portfolioEntity,
  portfolioName,
  possibleEntities,
  setPortfolioEntity,
  setPortfolioName,
  debouncedSearchObj,
  setSelectedObj,
  selectedObj,
  objList,
  disabled,
  isSupervisor,
}: Props): ReactElement => {
  const scrollToRef = useRef(null)
  const [currentAddId, setCurrentAddId] = useState<string>('')

  const CustomPopper = (props: PopperProps) => <Popper {...props} placement="bottom" />

  useEffect(() => {
    if (currentAddId && scrollToRef.current) {
      const ref = scrollToRef.current as HTMLElement
      ref.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [currentAddId])
  return (
    <div className="portfolio-form">
      <FormControl fullWidth>
        <FormLabel
          htmlFor="outlined-adornment-amount"
          required
        >
          {terms.Admin.portfolio.form.labelEntity}
        </FormLabel>
        <Autocomplete
          disabled={disabled || isSupervisor}
          fullWidth
          disablePortal
          loading={false}
          options={possibleEntities}
          getOptionLabel={option => option.libelle}
          isOptionEqualToValue={(option, value) => option.libelle === value.libelle}
          noOptionsText={terms.Common.noResults}
          value={portfolioEntity}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              id="outlined-controlled"
              size="small"
            />
          )}
          onChange={(_, value) => setPortfolioEntity(value)}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel
          htmlFor="outlined-adornment-amount"
          required
        >
          {terms.Admin.portfolio.form.labelLibelle}
        </FormLabel>
        <TextField
          disabled={disabled}
          fullWidth
          id="outlined-controlled"
          size="small"
          value={portfolioName}
          onChange={e => setPortfolioName(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel
          htmlFor="outlined-adornment-amount"
          required
        >
          {terms.Admin.portfolio.form.CLT}
        </FormLabel>
        <Autocomplete
          disabled={disabled}
          className="autocomplete-obj form-control form-control-sm"
          multiple
          disableCloseOnSelect
          sx={{ maxHeight: 250, overflowY: 'auto', overflowX: 'hidden' }}
          options={objList.sort((a, b) => a.libelle.localeCompare(b.libelle))}
          value={selectedObj.sort((a, b) => a.libelle.localeCompare(b.libelle))}
          onChange={(_: unknown, newValue) => {
            setSelectedObj(newValue)
            setCurrentAddId(newValue[newValue.length - 1]?.id)
          }}
          getOptionLabel={option => `${option.id}.${option.libelle}`}
          renderOption={(props, option, { selected }) => {
            if (!selected) {
              return (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <p
                    className="object-jalon-composant-label"
                  >
                    {option.libelle}
                  </p>
                </li>
              )
            }
            return (
              <li {...props} style={{ padding: 0 }} />
            )
          }}
          renderInput={params => (
            <TextField
              {...params}
              className="vertical-display"
              fullWidth
            />
          )}
          renderTags={tabValue => (
            <div className="obj-list">
              {tabValue.map(tab => (
                <ObjChip
                  readOnly={disabled}
                  obj={tab}
                  isAdded={currentAddId === tab.id}
                  key={tab.id}
                  handleDelete={() => setSelectedObj(selectedObj.filter(obj => obj.id !== tab.id))}
                  ref={currentAddId === tab.id ? scrollToRef : null}
                />
              ))}
            </div>
          )}
          isOptionEqualToValue={(opt, val) => opt.id === val.id}
          closeText=""
          openText=""
          noOptionsText={terms.Admin.noSearchText}
          onInputChange={(_, newInputValue) => debouncedSearchObj(newInputValue)}
          PopperComponent={CustomPopper}
        />
      </FormControl>
    </div>
  )
}

Form.defaultProps = {
  isSupervisor: false,
  disabled: false,
}

export default Form
