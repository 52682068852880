import {
  FormControl, InputLabel, MenuItem, Select, Box, Chip, SelectChangeEvent, Checkbox,
} from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { Entity } from 'reducers/Admin/Entity/types'

interface ContributionVisibilityProps {
  possibleEntities: Entity[]
  disabled: boolean
  visibility: string[]
  handleSelectEntity: (value: string[]) => void
  defaultEntityId?: string
  isGlobal?: boolean
}

const ContributionVisibility = ({
  possibleEntities, disabled, visibility, handleSelectEntity, defaultEntityId, isGlobal,
}: ContributionVisibilityProps): ReactElement => {
  const SELECT_ALL_VALUE = 'all'

  const handleSelectAll = () => {
    if (visibility.length === possibleEntities.length) {
      handleSelectEntity([...(defaultEntityId ? [defaultEntityId] : [])])
      return
    }
    handleSelectEntity([...possibleEntities.map(e => e.id as string)])
  }

  const handleChange = (evt: SelectChangeEvent<string[]>) => {
    const { target: { value } } = evt
    if (value.includes(SELECT_ALL_VALUE)) {
      handleSelectAll()
      return
    }
    const entityIdList: string[] = typeof value === 'string' ? [value] : value
    handleSelectEntity([...new Set([
      ...entityIdList,
      ...(defaultEntityId ? [defaultEntityId] : []),
    ])])
  }

  const renderChipValue = (selected: string[]) => {
    if (isGlobal) {
      return (
        <Chip
          label={terms.Contributions.visibility.allEntity}
        />
      )
    }
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {selected.filter(item => possibleEntities.find(e => e.id === item)?.libelle).map(value => (
          <Chip key={value} label={possibleEntities.find(e => e.id === value)?.libelle} />
        ))}
      </Box>
    )
  }

  return (
    <FormControl variant="standard" className="mb-3">
      <InputLabel id="select-visibility">
        {terms.Contributions.visibility.visibility}
      </InputLabel>
      <Select
        disabled={disabled}
        multiple
        value={visibility}
        onChange={handleChange}
        renderValue={selected => renderChipValue(selected as string[])}
      >
        <MenuItem
          key={SELECT_ALL_VALUE}
          value={SELECT_ALL_VALUE}
          className={visibility.length === possibleEntities.length ? 'Mui-selected' : ''}
          style={{ paddingLeft: '5px' }}
        >
          <Checkbox
            size="small"
            checked={visibility.length === possibleEntities.length}
          />
          <span>
            {visibility.length === possibleEntities.length
              ? terms.Contributions.visibility.unSelectAll
              : terms.Contributions.visibility.selectAll}
          </span>
        </MenuItem>
        { possibleEntities.map(e => (
          <MenuItem key={e.libelle} value={e.id}>
            <Checkbox
              size="small"
              checked={visibility.includes(e.id || '')}
            />
            <span>
              {e.libelle}
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

ContributionVisibility.defaultProps = {
  defaultEntityId: undefined,
  isGloabal: false,
}

export default ContributionVisibility
