import terms from 'common/terms'
import Loader from 'components/Loader'
import ModalConfirmation from 'components/Modal/ModalConfirmation/ModalConfirmation'
import ModalError from 'components/Modal/ModalError/ModalError'
import SettingsTab from 'components/SettingsTab/SettingsTab'
import CustomSnackbar from 'components/Snackbar/Snackbar'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  closeModalConfirmation, closeModalErrVisitor, onModalAddVisitor, onModalConfirmation,
  selectVisitor, selectVisitorEntity, hideSnackbarAdd, hideSnackbarDelete,
} from 'reducers/Parametrage/Visitors/visitors.reducer'
import { deleteSaVisitors, getSaVisitors, postSaVisitors } from 'reducers/Parametrage/Visitors/visitors.thunks'
import { UserRole } from 'reducers/Users/types'
import { setDisplayUserInfos, setUserInfosId } from 'reducers/Users/users.reducer'
import { RootState } from 'Store'

const Visitors = (): ReactElement => {
  const dispatch = useDispatch()
  const {
    selectedVisitor, selectedEntity, visitors, modalConfirmation, visitorToDeleteId, modalErrVisitor, errors,
    displaySnackbarAdd, displaySnackbarDelete, isLoadingVisitors,
  } = useSelector((state: RootState) => state.paramVisitors)
  const { userMeSettings } = useSelector((state: RootState) => state.users)
  const { idSA } = useSelector((state: RootState) => state.app)
  const saRoles = userMeSettings.find(elem => elem.id === idSA)?.roles || []

  useEffect(() => { dispatch(getSaVisitors(idSA)) }, [idSA])

  useEffect(() => {
    if (errors) {
      dispatch(onModalAddVisitor())
    }
  }, [errors])

  const handleDelete = () => {
    dispatch(deleteSaVisitors({
      saId: idSA,
      saVisitorId: visitorToDeleteId,
    }))
    dispatch(closeModalConfirmation())
  }

  const handleClickUser = (userId: string) => {
    dispatch(setUserInfosId(userId))
    dispatch(setDisplayUserInfos(true))
  }

  return (
    <>
      {
        isLoadingVisitors ? (
          <div className="setting-loader">
            <Loader message={terms.Common.loading} />
          </div>
        ) : (
          <SettingsTab
            user={selectedVisitor}
            entity={selectedEntity}
            disableEntity={!saRoles.some(role => [
              UserRole.admin,
              UserRole.animator,
              UserRole.animatorAxe,
              UserRole.entityAnimator,
            ].includes(role))}
            onUserChange={(_e, newValue) => dispatch(selectVisitor(newValue))}
            onEntityChange={(_e, newValue) => dispatch(selectVisitorEntity(newValue))}
            handleValidate={() => {
              dispatch(postSaVisitors({
                saId: idSA,
                user: selectedVisitor,
                entity: selectedEntity,
              }))
            }}
            handleDelete={(itemId?: string) => () => dispatch(onModalConfirmation(itemId))}
            handleClickUser={handleClickUser}
            emptyLabel={terms.Settings.visitor.noVisitor}
            items={visitors}
          />
        )
      }

      <ModalError
        onModal={modalErrVisitor}
        titleOption={terms.Settings.visitor.error.errorAddVisitor.title2}
        content={`${errors?.data[0]}.`}
        handleClose={() => dispatch(closeModalErrVisitor())}
      />
      <ModalConfirmation
        onModal={modalConfirmation}
        handleConfirmClick={handleDelete}
        content1={terms.Settings.visitor.modalConfirmation.content1}
        content2={terms.Settings.visitor.modalConfirmation.content2}
        handleClose={() => dispatch(closeModalConfirmation())}
      />

      <CustomSnackbar
        displaySnackbar={displaySnackbarAdd}
        message={terms.Settings.visitor.snackbarMessage.visitorAdded}
        handleClose={() => dispatch(hideSnackbarAdd())}
      />
      <CustomSnackbar
        displaySnackbar={displaySnackbarDelete}
        message={terms.Settings.visitor.snackbarMessage.visitorDeleted}
        handleClose={() => dispatch(hideSnackbarDelete())}
      />
    </>
  )
}

export default Visitors
