import { ReactElement } from 'react'
import {
  FaRssSquare, FaExternalLinkAlt, FaQuestionCircle, FaBookOpen,
} from 'react-icons/fa'
import terms from './common/terms'
import mainImage from './assets/images/home_main.png'

import './Home.scss'

export default function Home(): ReactElement {
  return (
    <main className="home-container">
      <article className="home-image">
        <img src={mainImage} alt="Robustest planning" className="main-image" />
      </article>
      <div className="welcome-container">
        <div className="header-links">
          <a
            className="header-link d-flex flex-column align-items-center shadow"
            target="forum"
            href="https://portail-robustest.fr"
          >
            <FaRssSquare size="25px" className="mb-2" />
            <span className="text-center">{terms.Home.portail}</span>
          </a>

          <a
            className="header-link d-flex flex-column align-items-center shadow"
            target="robustest"
            href="https://robustest.sncf.fr/pages/home"
          >
            <FaExternalLinkAlt size="25px" className="mb-2" />
            <span className="text-center">{terms.Home.old_version}</span>
          </a>
          <a
            className="header-link d-flex flex-column align-items-center shadow"
            href="mailto:martin.prieto@sncf.fr;"
          >
            <FaQuestionCircle size="25px" className="mb-2 question-circle" />
            <span className="text-center">{terms.Home.support}</span>
          </a>
          <a
            className="header-link d-flex flex-column align-items-center shadow"
            target="documentation"
            // eslint-disable-next-line max-len
            href="https://sncf.sharepoint.com/:f:/s/RobustestDocs/EsiIh9GpnKVJvl_J0z60CN0BWsBXqg9h3TISOmXVzgMltw?e=67m48G"
          >
            <FaBookOpen size="25px" className="mb-2 book" />
            <span className="text-center">{terms.Home.h00}</span>
          </a>
        </div>
      </div>
    </main>
  )
}
