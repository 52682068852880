/* eslint-disable max-len */
export const terms = {
  errorMessages: {
    forbidden: 'Vous n\'avez pas les droits pour effectuer cette action.',
    notFound: 'La ressource demandée n\'existe pas.',
    internalServerError: 'Une erreur est survenue.',
    serverDown: 'Le serveur est indisponible.',
    unknownError: 'Une erreur inconnue est survenue.',
    badRequest: 'Vérifiez les informations que vous avez entrées et réessayez.',
    failedDependency: 'Une dépendance du projet a rencontré une erreur, veuillez réessayer.',
  },
  Common: {
    ok: 'OK',
    noResults: 'Pas de résultats',
    loading: 'Chargement',
    confirm: 'Confirmer',
    cancel: 'Annuler',
    composants: 'Composants',
    selectPlaceholder: 'Aucun composant sélectionné',
    defaultComponent: 'Composant suggéré par l\'administrateur',
    add: 'Ajouter',
    validate: 'Valider',
    valid: 'Valider les modifications',
    confirmation: 'Confirmation',
    info: 'Message d\'information:',
    contactMail: 'Par mail: ',
    contactPhone: 'Par téléphone: ',
    readOnly: 'Lecture seule',
    delete: 'Supprimer',
    modify: 'Modifier',
    import: 'Importer',
    select: 'Sélectionner',
    selectSaPlaceholder: 'Aucun SA sélectionné',
    rejected: 'Rejeter',
    closeAction: 'Clôturer',
    reopenAction: 'Déclôturer',
    finishAction: 'Réaliser',
    save: 'Sauvegarder',
    modalUserInfos: {
      title: 'Informations utilisateur',
      displayName: 'Nom :',
      username: 'CP :',
      email: 'Mail :',
      loadingMessage: 'Chargement des informations de l\'utilisateur',
    },
    userSearch: {
      noResults: 'Pas de résultats',
      condition: (text: string) => `${text} (3 caractères minimum)`,
    },
  },
  FeedbackMailto: {
    title: 'Signaler un problème',
    subTitle: 'Veuillez saisir vos commentaires ci-dessous :',
    labelOject: 'Objet',
    labelNavigator: 'Navigateur',
    labelContent: 'Que s\'est-il passé ?',
    send: 'Ouvrir la messagerie',
    popOverNavigator: 'Nom du navigateur. Exemple : "Google Chrome"',
    popOverContent: 'Description du problème. Exemple : "Lorsque je clique sur le composant Capacité des voies de service, j\'ai une page blanche."',
    nbCharMax: '1500',
    char: 'caractères',
    popOverObject: 'Objet du mail. Exemple : "Problème sur le composant Capacité des voies de service"',
    labelCheckbox: 'Inclure des données sur votre environnement actuel, comme le navigateur et l\'URL de la page. Cela nous permet de mieux comprendre votre commentaire',
    navigatorInstalled: 'Navigateurs installés :',
    navigatorUsed: 'Navigateur utilisé :',
    os: 'Système d\'exploitation :',
    screenWidth: 'Largeur de l\'écran :',
    screenHeight: 'Hauteur de l\'écran :',
    buttonDataInformation: 'Quelles informations sont incluses dans les données relatives à mon environnement actuel ?',
    url: 'URL :',
  },
  Nav: {
    home: 'Accueil',
    admin: 'Administration',
    addSa: 'Création SA',
    trackingBoard: 'Tableau de suivi',
    settingsSa: 'Paramétrage SA',
    settings: 'Paramétrage',
    monitoring: {
      title: 'Supervision',
      path: '/supervision/',
    },
  },
  ContributorFilter: {
    allContributors: 'Tous les contributeurs',
  },
  Home: {
    portail: 'Portail Robus’test',
    old_version: 'Ancienne version',
    support: 'Contacter le support',
    h00: 'Portail H00',
    sideText: 'Articulation de la démarche avec les calendriers de conception',
    sideImageAlt: 'Articulation de la démarche avec les calendriers de conception',
  },
  Admin: {
    entity: {
      name: 'Entités',
      new: 'Nouvelle entité',
      placeHolder: 'Nom de l\'entitée',
      supervisor: 'Superviseurs',
      noSearch: 'Chercher un utilisateur',
      validationNotice: 'L\'entité a bien été enregistrée',
    },
    eic: {
      name: 'Territoires',
      new: 'Nouveau territoire',
      placeHolder: 'Nom du territoire',
      animator: 'Animateurs',
      visitor: 'Visiteurs',
      validationNotice: 'Le territoire a bien été enregistré',
    },
    axes: {
      name: 'Axes',
      new: 'Nouvel axe',
      placeHolder: 'Nom de l\'axe',
      animator: 'Animateurs',
      visitor: 'Visiteurs',
      validationNotice: 'L\'axe a bien été enregistré',
    },
    composants: {
      name: 'Composants',
      new: 'Nouveau composant',
      placeHolder: 'Nom du composant',
      tabsLabel: 'tab sur les jalon',
      complexe: 'Complexe',
      ligne: 'Ligne',
      chantier: 'Chantier de travaux',
      zoneDeProduction: 'Zone de production',
      addPdf: 'Joindre un pdf',
      updatePdf: 'Modifier le pdf',
      pdfLinkLabel: 'Voir le pdf',
      deletePdf: 'Supprimer le pdf',
      on: 'Activé pour ce jalon',
      off: 'Désactivé pour ce jalon',
      validationNotice: 'Le composant a bien été enregistré',
    },
    contributorFunction: {
      name: 'Fonctions contributeurs',
      new: 'Nouvelle fonction',
      namePlaceHolder: 'Nom du fonction',
      entity: 'Entité',
      reset: 'Réinitialiser les composants de la fonction pour ce jalon',
      hasNoComposants: '(Cette fonction n\'a pas de composant)',
      validationNotice: 'La fonction a bien été enregistrée',
    },
    productionZones: {
      name: 'Zones de production',
      new: 'Nouvelle zone de production',
      namePlaceHolder: 'Nom de la zone de Production',
      abrevPlaceHolder: 'Abréviation de la zone de production',
      validationNotice: 'La zone a bien été enregistrée',
    },
    constructionSite: {
      name: 'Chantiers de travaux',
      withoutTerritory: '(Sans territoire)',
      new: 'Nouveau chantier de travaux',
    },
    complexes: {
      name: 'Complexes',
      codeCi: 'Code CI',
      searchPlaceholder: 'Complexe (Ex: St-André)',
      associatedTo: 'Associé au territoire',
      confirmation: 'Confirmation',
      confirmationText: 'Êtes-vous sûr de vouloir dissocier le complexe ',
      confirmationText2: 'du territoire',
    },
    ponderation: {
      name: 'Pondération des composants',
      message: {
        error: {
          title: 'Message d\'erreur',
          text: 'La pondération des cotations doit être supérieure à 0',
          composantText: 'La pondération des composants doit être supérieure à 0',
        },
      },
      snackbars: {
        success: 'La pondération a bien été enregistrée',
        error: 'Une erreur est survenue lors de l\'enregistrement de la pondération',
      },
      columnsName: [
        'Numéro',
        'Composant',
        'Complexe',
        'Ligne',
        'Travaux',
      ],
    },
    portfolio: {
      name: 'Portefeuilles de supervision génériques',
      new: 'Nouveau portefeuille de supervision',
      placeHolder: 'Nom du portefeuille de supervision',
      validationNotice: 'Le portefeuille de supervision a bien été enregistré',
      form: {
        labelEntity: 'Entité',
        labelLibelle: 'Nom du portefeuille',
        CLT: 'Complexes, Lignes, Chantiers de travaux',
      },
      map: {
        zoom: 'Zoom',
        popup: {
          libelle: 'libellé',
        },
      },
    },
    title: 'Administration',
    name: 'Libellé',
    abrev: 'Abréviation',
    on: 'Activée',
    off: 'Désactivée',
    validate: 'Valider',
    close: 'Fermer',
    noSearchText: 'Pas de résultat',
    toSave: 'À enregistrer',
  },
  CreateSA: {
    title1: 'création d\'un',
    title2: 'service annuel',
    content: 'La création d\'un service annuel génère automatiquement des jalons avec une date de COPIL prévisionnelle. Veuillez renseigner une année et un territoire ou un axe afin de paramètrer la liste des complexes, lignes, décideurs et contributeurs. Veuillez noter qu\'un paramètrage sera pré-initialisé sur la base du service annuel précédent.',
    selectTitle: 'Choisissez une année et un territoire ou axe pour le SA',
    button: 'Créer le SA',
    axe: 'Axes',
    territories: 'Territoires',
  },
  Settings: {
    reserved: 'Paramétrage réservé à l’EIC d’origine.',
    complexe: {
      tab: 'Complexes',
      listActive: 'Liste des complexes activés pour le SA.',
      listNotActive: 'Liste des complexes non-activés. Activez par glisser-déposer.',
      search: 'Rechercher un complexe',
      placeholder: 'Complexe (Ex: St-André)',
      error: {
        errorAddComplexe: {
          title: 'Message d\'information:',
          title2: 'Ajout d\'un complexe externe au territoire',
          contentTitleComplexe: 'Ce complexe appartient au territoire ',
          contactName: 'Contactez l\'administrateur PRIETO Martin',
          constactMail: 'par mail ',
          mail: 'martin.prieto@reseau.sncf.fr',
          contactTel: 'ou par téléphone ',
          tel: '+33547472012',
        },
      },
      composantModal: {
        title: 'Paramétrage du complexe',
      },
      modalConfirmation: {
        content1: 'Êtes-vous sûr de vouloir dissocier le complexe ',
        content2: 'du territoire ',
      },
      popup: 'Dissocier ce complexe du territoire',
      snackbarMessage: {
        complexeAdded: 'Le complexe a bien été ajouté',
        complexeUpdated: 'Le complexe a bien été mis à jour',
        complexeDeleted: 'Le complexe a bien été supprimé',
      },
    },
    lignes: {
      tab: 'Lignes',
      popup: 'Dissocier cette ligne du territoire',
      columns: {
        listActive: 'Liste des lignes activées pour le SA.',
        listNotActive: 'Liste des lignes non-activées. Activez par glisser-déposer.',
      },
      composantModal: {
        title: 'Paramétrage de la ligne',
      },
      modalConfirmation: {
        content1: 'Êtes-vous sûr de vouloir dissocier la ligne ',
        content2: 'du territoire ',
      },
      search: {
        depart: 'Rechercher un complexe de départ',
        fin: 'Rechercher un complexe d\'arrivée',
        via: 'Rechercher un complexe VIA',
      },
      error: {
        errorAddLigne: {
          title: 'Message d\'information:',
          title2: 'Ajout d\'une ligne externe au territoire',
          contentTitleComplexe: 'Cette ligne appartient au territoire ',
          contactName: 'Contactez l\'administrateur PRIETO Martin',
          constactMail: 'par mail ',
          mail: 'martin.prieto@reseau.sncf.fr',
          contactTel: 'ou par téléphone ',
          tel: '+33547472012',
        },
      },
      snackbarMessage: {
        ligneAdded: 'La ligne a bien été ajoutée',
        ligneUpdated: 'La ligne a bien été mise à jour',
        ligneDeleted: 'La ligne a bien été supprimée',
      },
    },
    constructionSite: {
      tab: 'Chantiers travaux',
      listActive: 'Liste des chantiers travaux activés pour le SA.',
      listNotActive: 'Liste des chantiers travaux non-activés. Activez par glisser-déposer.',
      search: 'Rechercher un chantier travaux',
      placeholder: 'Chantier travaux (Ex: St-André)',
      addConstructionSite: 'Ajouter un chantier travaux',
      error: {
        errorAddConstructionSite: {
          title: 'Message d\'information:',
          title2: 'Ajout d\'un chantier travaux externe au territoire',
          contentTitleConstructionSite: 'Ce chantier travaux appartient au territoire ',
          contactName: 'Contactez l\'administrateur PRIETO Martin',
          constactMail: 'par mail ',
          mail: 'martin.prieto@reseau.sncf.fr',
          contactTel: 'ou par téléphone ',
          tel: '+33547472012',
        },
      },
      composantModal: {
        title: 'Paramétrage du chantier travaux',
      },
      modalConfirmation: {
        content1: 'Êtes-vous sûr de vouloir dissocier le chantier travaux ',
        content2: 'du territoire ',
      },
      modalCreateConstructionSite: {
        title: 'Ajouter un chantier de travaux',
        inputPlaceHolder: 'Libellé du chantier de travaux',
      },
      popup: 'Dissocier ce chantier travaux du territoire',
      snackbarMessage: {
        constructionSiteAdded: 'Le chantier travaux a bien été ajouté',
        constructionSiteUpdated: 'Le chantier travaux a bien été mis à jour',
        constructionSiteDeleted: 'Le chantier travaux a bien été supprimé',
      },
    },
    contributor: {
      tab: 'Contributeurs',
      user: 'Utilisateur',
      userSearchPlaceholder: 'NOM Prénom (Ex: DURANT Jean)',
      noUserSearch: 'Chercher un utilisateur',
      function: 'Fonction',
      functionSearchPlaceholder: 'Fonction (Ex: DCS)',
      noFunctionSearch: 'Chercher une fonction',
      perimeter: 'Périmètre',
      listComplexe: 'Complexes',
      listLigne: 'Lignes',
      listTravaux: 'Travaux',
      allComplexes: 'Tous les complexes',
      allLignes: 'Toutes les lignes',
      allTravaux: 'Tous les chantiers',
      noContributor: 'Aucun contributeur',
      modifyContributor: 'Modifier le contributeur et ses composants',
      error: {
        errorAddContributor: {
          title: 'Message d\'information:',
          title2: 'Ajout d\'un contributeur au SA',
        },
      },
      modalConfirmation: {
        content1: 'Êtes-vous sûr de vouloir supprimer',
        content2: 'le contributeur',
      },
      composantModal: {
        title: 'Paramétrage du contributeur',
        jalonTabTitle: 'Composants',
      },
      snackbarMessage: {
        contributorAdded: 'Le contributeur a bien été ajouté',
        contributorUpdated: 'Le contributeur a bien été mis à jour',
        contributorDeleted: 'Le contributeur a bien été supprimé',
      },
    },
    settingsTab: {
      form: {
        user: 'Utilisateur',
        userSearch: 'Chercher un utilisateur',
        userSearchPlaceholder: 'NOM Prénom (Ex: DURANT Jean)',
        entity: 'Entité',
        entitySearch: 'Chercher une entité',
        entitySearchPlaceholder: 'Entité (Ex: Production TER)',
      },
    },
    decider: {
      tab: 'Décideurs',
      noDecider: 'Aucun décideur',
      modalConfirmation: {
        content1: 'Êtes-vous sûr de vouloir supprimer',
        content2: 'le décideur',
      },
      error: {
        errorAddDecider: {
          title: 'Message d\'information:',
          title2: 'Ajout d\'un décideur au SA',
        },
      },
      snackbarMessage: {
        deciderAdded: 'Le décideur a bien été ajouté',
        deciderDeleted: 'Le décideur a bien été supprimé',
      },
    },
    visitor: {
      tab: 'Visiteurs',
      noVisitor: 'Aucun visiteur',
      modalConfirmation: {
        content1: 'Êtes-vous sûr de vouloir supprimer',
        content2: 'le visiteur',
      },
      error: {
        errorAddVisitor: {
          title: 'Message d\'information:',
          title2: 'Ajout d\'un visiteur au SA',
        },
      },
      snackbarMessage: {
        visitorAdded: 'Le visiteur a bien été ajouté',
        visitorDeleted: 'Le visiteur a bien été supprimé',
      },
    },
    entityAnimator: {
      role: 'Animateur d’entité',
      tab: 'Animateurs d’entité',
      noAnimator: 'Aucun animateur d’entité',
      modalConfirmation: {
        content1: 'Êtes-vous sûr de vouloir supprimer',
        content2: 'l\'animateur entité',
      },
      error: {
        errorAddAnimator: {
          title: 'Message d\'information:',
          title2: 'Ajout d\'un animateur entité au SA',
        },
      },
      snackbarMessage: {
        animatorAdded: 'L\'animateur entité a bien été ajouté',
        animatorDeleted: 'L\'animateur entité a bien été supprimé',
      },
    },
    preferences: {
      tab: 'Préférences',
      mailSectionTitle: 'Mails automatiques',
      preferenceSectionTitle: 'Préférences d’affichage',
      mailOpenLabel: 'Envoyer un mail automatique à tous les acteurs du SA lors de l’ouverture d’un jalon',
      mailRemindLabel: 'Envoyer un mail automatique de rappel à l’animateur si le jalon n’est pas clôturé à temps',
      expandAction: 'Déplier toutes les actions par défaut',
      snackbarMessage: 'Les réglages ont bien été enregistrés',
    },
    objectSetting: 'Paramétrage du complexe',
    resetComplexeJalon: 'Réinitialiser les composants de ce complexe pour ce jalon',
    resetCSJalon: 'Réinitialiser les composants de ce chantier pour ce jalon',
    resetLigneJalon: 'Réinitialiser les composants de cette ligne pour ce jalon',
    resetContribJalon: 'Réinitialiser les composants de ce contributeur pour ce jalon',
    resetJalon: 'Réinitialiser les composants de ce jalon',
  },
  errors: {
    error: 'Une erreur est survenue',
  },
  Suivi: {
    tileWord: 'sur',
    tileEval: 'évaluation',
    tileWaiting: 'attendue',
    filterSelect: 'Filtrer par contributeur',
    modifySa: 'Modifier le SA',
    coming: 'A venir',
    onGoing: 'En cours',
    toBeValidated: 'En cours de validation',
    closed: 'Clôturé',
    statusChangeValidation: 'Confirmer le changement de statut',
    validationMessage: 'Désirez-vous passer le jalon au statut',
    comingNotice: 'Les contributeurs n\'auront plus accès à ce jalon.',
    onGoingNotice: 'Les contributeurs pourront contribuer pour ce jalon.',
    toBeValidatedNotice: 'Les contributeurs ne pourront plus contribuer mais uniquement consulter.',
    toBeValidatedFollowingNotice: 'Le plan d\'action sera disponible et vous suggérera des composants à travailler selon vos évaluations.',
    closedNotice: 'Attention cette action est irréversible et placera ce jalon en lecture seule.',
    dateNotice: 'Date prévisionnelle',
    previsionnelleDate: 'La date affichée est prévisionnelle. Veuillez définir une date de COPIL',
    copilDate: 'Date de COPIL',
    dateChangeValidation: 'Confirmer le changement de la date de COPIL',
    dateValidationMessage: 'Êtes-vous sûr de vouloir changer la date de COPIL au',
    labelToggleSwitchComponent: 'Afficher tous les composants',
    labelToggleSwitchAllContribution: 'Afficher toutes les contributions',
    popOverRatio: 'Aucun contributeur ne peut contribuer pour ce composant. Vérifiez les paramètres du Service Annuel',
    labelToggleComponentInArray: 'Affichage des composants en tableau',
    tabAxe: 'Non-exclusif à l\'axe',
    arrayComponent: {
      component: 'Composant',
      evaluation: 'Évaluation',
      date: 'Date',
      author: 'Auteur',
      analysis: 'Analyse',
      proposal: 'Proposition',
      noContribution: 'Aucune contribution',
      noAnalysis: 'Aucune analyse',
      noProposal: 'Aucune proposition',
      linkComponent: 'Voir le composant',
    },
    pdfTerms: {
      button: 'Export avec analyse',
      setting: {
        title: 'Export des données avec analyses',
        warningTerms: 'Attention! Vous allez exporter des données potentiellement confidentielles !',
        checkboxTerms: 'J\'ai compris les risques',
        buttonExport: 'Export PDF',
        buttonClose: 'Fermer la fenêtre',
      },
      bv: {
        complexes: {
          title: 'Complexes',
          allComplexes: 'Tous les complexes',
          notComplex: 'Aucun complexe',
        },
        lignes: {
          title: 'Lignes',
          allLines: 'Toutes les lignes',
          notLine: 'Aucune ligne',
        },
        travaux: {
          title: 'Chantiers de travaux',
          allTravaux: 'Tous les chantiers de travaux',
          notTravaux: 'Aucun chantier de travaux',
        },
      },
      entity: {
        title: 'Entités',
        allEnities: 'Toutes les entités',
        notEntity: 'Aucune entité',
      },
      toggleConditionToDisplay: {
        globalAnimator: 'Afficher les contributions globales de l\'animateur',
        specificAnimator: 'Afficher les contributions spécifiques de l\'animateur',
        contributor: 'Afficher les contributions des contributeurs',
        decider: 'Afficher les contributions des décideurs',
        toggleTrue: 'oui',
        toggleFalse: 'non',
        howToDisplay: {
          title: 'Affichage',
          perComponent: 'Par composant',
          perObject: 'Par complexe/ligne/travaux',
        },
      },
    },
    GlobalContribution: {
      labelCotation: 'Cotation donnée =',
      labelGravity: 'Gravité =',
      labelComposite: 'Indice composite =',
      labelComment: 'COMMENTAIRE',
      Button: {
        save: 'Sauvegarder la cotation',
        conditionForSave: 'Pour une cotation M ou I, veuillez choisir une gravité et saisir un commentaire pour valider',
        cancel: 'Quitter sans donner de cotation',
      },
      SnackBar: {
        error: 'Une erreur est survenue',
        success: 'La cotation a bien été enregistrée',
      },
      PopOver: {
        composite: 'Indice composite',
        globalCotationActiv: 'Cotation globale',
        complexe: 'Le bâtiment voyageurs pourra être coté quand le jalon est “En cours de validation”',
        ligne: 'La ligne pourra être cotée quand le jalon est “En cours de validation”',
        travaux: 'Le chantier travaux pourra être coté quand le jalon est “En cours de validation”',
      },
      globalCotation: 'Cotation globale :',
      globalCotationAnimaxe: 'Cotation globale Animateur d\'axe :',
    },
    mailTerms: {
      sendMail: 'Envoi de mail',
      targets: 'Destinataires',
      mail: 'Mail',
      object: 'Objet',
      content: 'Contenu',
      closeModal: 'Fermer la fenêtre',
      openMailbox: 'Ouvrir la messagerie',
      selectAll: 'Tout cocher',
      unselectAll: 'Tout décocher',
      role: {
        animator: 'Animateur',
        decider: 'Décideur',
        contributor: 'Contributeur',
        entityAnimator: 'Animateur d\'entité',
      },
    },
  },
  Contributions: {
    navigator: {
      previousComponent: 'Aller au composant précédent',
      nextComponent: 'Aller au composant suivant',
      info: 'Afficher la description du composant',
    },
    list: {
      noList: 'Aucune contribution à afficher',
      onlyHistory: 'Aucune contribution pour le jalon en cours',
      showHistory: "Afficher l'historique des contributions",
      hideHistory: "Cacher l'historique des contributions",
      noHistory: 'Aucun historique',
    },
    tile: {
      analysis: 'Analyse',
      noAnalysis: 'Aucune analyse',
      openFile: 'Télécharger',
      proposition: 'Proposition',
      noProposition: 'Aucune proposition',
      tileAction: 'Dupliquer',
      tileActionPopover: 'Reconduire la contribution',
      writtenBy: 'Ecrite par :',
      multipleEntites: 'Contribution visible par plusieurs entités',
      axeHabilitation: 'Contribution faite à partir du SA de l’axe',
    },
    editor: {
      analysis: 'Analyse',
      analysisPlaceholder: 'Renseignez une analyse',
      proposition: 'Proposition',
      propositionPlaceholder: 'Renseignez une proposition',
      file: 'Fichier',
      addFile: 'Joindre un fichier',
      seeFile: 'Voir le fichier',
      deleteFile: 'Supprimer le fichier',
      globalContrib: 'Contribution Globale',
      specificContrib: 'Contribution Spécifique',
      axeTransfert: 'Axes habilités à voir la contribution',
      emptyAxe: 'Aucun axe à habiliter',
      emptyAxeInAxe: 'Aucun axe supplémentaire à habiliter',
    },
    visibility: {
      visibility: 'Entités habilitées à voir la contribution',
      selectAll: 'Tout sélectionner',
      unSelectAll: 'Tout désélectionner',
      allEntity: 'Toutes les entités',
    },
    impersonate: {
      label: 'Contribuer pour :',
      selfContribute: 'Contribuer pour soi-même',
      animator: 'Animateur',
    },
    duping: {
      duping: 'Dédoublement',
      modalHeader: 'Sélectionner le(s) complexe(s)/ligne(s)/chantier(s) vers lesquels dédoubler la contribution',
      territories: 'Territoires',
      objects: 'Complexe(s)/Ligne(s)/Chantier(s)',
      noDupeLabel: 'Cliquer ici pour dédoubler la contribution vers un autre complexe/ligne/chantier',
      dupeOn: 'Dédoublement sur',
      xObjects: 'complexe(s)/ligne(s)/chantier(s)',
    },
    modalConfirmation: {
      content1: 'Êtes-vous sûr de vouloir ',
      content2Add: 'soumettre votre contribution ',
      content2Delete: 'supprimer votre contribution ',
      content2Patch: 'modifier votre contribution ',
    },
    snackbars: {
      add: 'La contribution a bien été ajoutée',
      delete: 'La contribution a bien été supprimée',
      patch: 'La contribution a bien été modifiée',
    },
  },
  actionPlan: {
    toggleDisplayAllActions: 'Afficher toutes les actions',
    pdf: {
      copil: 'copil',
      roadMap: 'route',
    },
    exportRoute: 'Exporter la feuille de route',
    exportCopil: 'Exporter Copil',
    namePdf: 'feuille-de-route',
    nameCopil: 'reunion-copil',
    noAction: 'Aucune action',
    column: {
      toDo: {
        title: 'Composants à travailler',
        empty: 'Aucun composant à travailler',
        buttonModify: 'Modifier les composants à travailler',
      },
      inProgress: {
        title: 'Actions à réaliser',
        empty: 'Aucune action à réaliser',
      },
      done: {
        title: 'Actions Réalisées',
        empty: 'Aucune action réalisée',
      },
      closed: {
        title: 'Actions clôturées',
        empty: 'Aucune action clôturée',
      },
    },
    copilMode: {
      column: {
        action: {
          title: 'Actions à valider',
          empty: 'Aucune action',
        },
        actionValidate: {
          title: 'Actions validées',
          empty: 'Aucune action validée',
        },
        actionReject: {
          title: 'Actions rejetées',
          empty: 'Aucune action rejetée',
        },
      },
    },
    status: {
      toDo: 'À réaliser',
      finished: 'Réalisée',
      closed: 'Clôturée',
    },
    state: {
      toClear: 'À valider',
      validated: 'Validée',
      rejected: 'Rejetée',
      none: 'None',
    },
    displayToggle: {
      roadmap: 'Feuille de route',
      copil: 'Réunion COPIL',
    },
    modalComposant: {
      title: 'Modifier les composants à travailler',
      buttonConfirm: 'Valider les modifications',
    },
    actionTile: {
      weatherA: 'A:',
      weatherPA: 'PA:',
      cloneAction: 'Dupliquer',
      cloneActionPopover: 'Dupliquer l\'action',
    },
    modalAddAction: {
      term: 'Analyses & propositions',
      addAction: 'Ajouter une action',
      formAddActionTitle: 'Création d\'action',
      formAddActionValidate: 'Créer l\'action',
      formPatchActionTitle: 'Modification d\'action',
      formPatchActionValidate: 'Modifier l\'action',
      formViewActionTitle: 'Visualisation de l\'action',
      formCloneActionTitle: 'Duplication sur',
      formCloneActionValidate: 'Dupliquer l\'action',
      form: {
        openDate: 'Date d\'ouverture',
        closeDate: 'Date de bouclage',
        terms: 'Cette action nécessite une validation dans un comité de pilotage (mode Réunion Copil)',
        buttonConfirm: 'Créer l\'action',
        champName: 'Nom de l\'action',
        champEntite: 'Entité(s) de pilotage',
        champContribution: 'Aucune contribution',
        champDescription: 'Description',
        champPA: 'Pilote',
        champCopilote: 'Co-pilote',
        finishDate: 'Date de réalisation',
        closureDate: 'Date de clôture',
      },
      titlecolAction: 'Liste des actions',
      contribution: {
        analyse: 'Analyse',
        proposition: 'Proposition',
        noProposition: 'Aucune proposition',
        noAnalyse: 'Aucune analyse',
      },

      tileAction: {
        dateClose: 'Bouclage',
        dateOpen: 'Ouverture =',
        noPilot: 'Aucun pilote désigné',
        noCoPilot: 'Aucun co-pilote désigné',
        plannedDate: 'Date de bouclage',
        closingDate: 'Date de clôture',
        finishedDate: 'Date de réalisation',
        noDate: '--/--/----',
      },
      addActionSucess: 'L\'action a bien été ajoutée',
      addActionError: 'Une erreur est survenue lors de l\'ajout de l\'action',
      cloneActionSucess: 'L\'action a bien été dupliquée',
      cloneActionError: 'Une erreur est survenue lors de la duplication de l\'action',
    },
    modalActionCopile: {
      avancementPA: 'Commentaire des pilote et co-pilote',
      avancementA: 'Commentaire de l’animateur',
      decision: 'Décision',
      ifnoAvancementA: 'En attente du commentaire de l\'animateur',
      ifnoAvancementPA: 'En attente du commentaire du pilote d\'action',
      ifnoDecision: 'En attente de la décision du COPIL',
      writtenByAnim: 'Écrit par l\'animateur',
      entities: 'Entités:',
      noEntities: 'Aucune entité associée à l\'action',
      hintDisabledClosing: 'Renseigner l\'état d’avancement et la météo de l\'animateur pour pouvoir clôturer l’action',
      hintCloseAction: 'Clôturer l\'action',
      hintDisabledRealise: 'Renseigner l\'état d’avancement et la météo du pilote d\'action pour pouvoir réaliser l’action',
      hintRealiseAction: 'Réaliser l\'action',
    },
    errorSnackbar: {
      updateAction: 'Une erreur est survenue lors de la modification de l\'action',
      copilAction: 'Une erreur est survenue',
    },
    successSnackbar: {
      updateAction: 'L\'action a bien été modifiée',
    },
  },
  Monitoring: {
    Dzp: {
      title: 'Cotations par zone de production',
      noData: 'Aucune donnée',
      noPortfolio: 'Veuillez sélectionner un portefeuille',
      noComment: 'Aucun commentaire',
      Table: {
        component: 'Composant',
        composite: 'Indice composite',
        global: 'Cotation globale',
        gravity: 'Gravité',
      },
      Selects: {
        territory: 'Territoire',
        select: 'sélectionné',
        year: 'Année',
        zp: 'Zone de production',
      },
      cotations: {
        waiting: 'En attente de cotation pour ce composant',
        notWaiting: 'Pas de cotation attendue pour ce composant',
        component: 'composant',
        popCotation: 'Cliquer pour voir l\'analyse et la proposition de l\'animateur',
        popper: {
          title: 'Analyse et proposition',
          animator: 'Animateur',
          analyse: 'Analyse',
          proposition: 'Proposition',
        },
      },
    },
    Contributions: {
      title: 'Contributions par transporteur',
      Selects: {
        entity: 'Entité',
        portfolio: 'Portefeuille',
        year: 'Année',
        componentSelectLabel: (value: string[]) => `${value.length} 
            composant${value.length > 1 ? 's' : ''} 
            sélectionné${value.length > 1 ? 's' : ''}`,
      },
      Filters: {
        animator: 'Animateur',
        contributor: 'Contributeur',
        ordering: 'Trier par:',
        objectFerroviaire: 'objet ferroviaire',
        component: 'composant',
        global: 'Animateur (Contribution globale)',
        withoutContribution: 'Composant sans contribution',
        spe: 'Animateur (Contribution spécifique)',
      },
      Table: {
        component: 'Composant',
        evaluation: 'Évaluation',
        author: 'Auteur',
        analysis: 'Analyse',
        proposal: 'Proposition',
        action: 'Action',
      },
      Action: {
        modal: {
          title: 'Information sur l\'action',
          animatorProgress: 'état d\'avancement de l\'animateur',
          pilotProgress: 'état d\'avancement du pilote',
        },
      },
    },
  },
  SearchGaiaLigne: {
    libelle: 'Libellé de la ligne',
    placeholderLibelle: 'Chercher une ligne par son libellé',
    code: 'Code de la ligne',
    placeholderCode: 'Chercher une ligne par son code',
    pk: 'Point kilométrique',
  },

  ParametragePortfolio: {
    title: 'Portefeuilles de supervision',
    importButton: 'Importer un portefeuille',
    genericPortfolio: 'Portefeuilles génériques',
    create: {
      title: 'Créer un portefeuille',
      createButton: 'Créer le portefeuille',
      messageSuccess: 'Le portefeuille a bien été créé',
    },
    modify: {
      title: 'Modifier le portefeuille',
      messageSuccess: 'Le portefeuille a bien été modifié',
    },
    delete: {
      title: 'Confirmer la suppression du portefeuille',
      messageSuccess: 'Le portefeuille a bien été supprimé',
    },
  },
} as const

export default terms
