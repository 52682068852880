import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import {
  deleteSaContributeurs, getSaContributeurs, postSaContributeurs,
} from 'reducers/Parametrage/Contributeurs/contributeurs.thunk'
import {
  checkComplexe, checkLigne, checkTravaux, closeModalConfirmation, closeModalErrContributor, onModalAddContributor,
  uncheckComplexe, uncheckLigne, uncheckTravaux, selectFunction,
  hideSnackbarAdd, hideSnackbarDelete, hideSnackbarUpdate,
} from 'reducers/Parametrage/Contributeurs/contributeurs.reducer'
import terms from 'common/terms'
import ModalError from 'components/Modal/ModalError/ModalError'
import ModalConfirmation from 'components/Modal/ModalConfirmation/ModalConfirmation'
import CustomSnackbar from 'components/Snackbar/Snackbar'
import Loader from 'components/Loader'
import ModalPatchContributors from './modalContributors/ModalPatchContributors'
import ContributorsList from './contributorsList/ContributorsList'
import ContributorsAdd from './contributorsAdd/ContributorsAdd'
import './contributors.scss'
import '../settings.scss'

const Contributors = (): ReactElement => {
  const dispatch = useDispatch()

  const {
    contributeurs, modalConfirmation, contributorToDeleteId, addContributorSa,
    selectedContributor, selectedFunction, modalErrContributor, errors,
    displaySnackbarAdd, displaySnackbarDelete, displaySnackbarUpdate, isLoadingContributors,
  } = useSelector((state: RootState) => state.paramContributeurs)
  const {
    contributorFunctions,
    isLoadingContributorFunctions,
  } = useSelector((state: RootState) => state.contributorFunction)
  const { account } = useSelector((state: RootState) => state.user)
  const { idSA } = useSelector((state: RootState) => state.app)

  useEffect(() => {
    dispatch(getSaContributeurs(idSA))
  }, [idSA])

  useEffect(() => {
    if (errors) {
      dispatch(onModalAddContributor())
    }
  }, [errors])

  const handleDelete = () => {
    dispatch(deleteSaContributeurs({
      saContributorId: contributorToDeleteId,
      saId: idSA,
      needRefresh: account.id === contributeurs.find(c => c.id === contributorToDeleteId)?.cerbereId,
    }))
    dispatch(closeModalConfirmation())
  }
  return (
    <>
      {
        (isLoadingContributorFunctions || isLoadingContributors) ? (
          <div className="setting-loader">
            <Loader message={terms.Common.loading} />
          </div>
        ) : (
          <div className="d-flex contributor-container">
            <div className="d-flex contributor-panel">
              <ContributorsAdd
                contributor={addContributorSa}
                user={selectedContributor}
                userFunction={selectedFunction}
                functionOptions={contributorFunctions.filter(func => func.active)}
                onFunctionChange={(_e, newValue) => dispatch(selectFunction(newValue))}
                handleCheckComplexe={elem => (_e, checked) => {
                  if (elem) {
                    if (checked) {
                      dispatch(checkComplexe(elem))
                    } else {
                      dispatch(uncheckComplexe(elem))
                    }
                  }
                }}
                handleCheckLigne={elem => (_e, checked) => {
                  if (elem) {
                    if (checked) {
                      dispatch(checkLigne(elem))
                    } else {
                      dispatch(uncheckLigne(elem))
                    }
                  }
                }}
                handleCheckTravaux={elem => (_e, checked) => {
                  if (elem) {
                    if (checked) {
                      dispatch(checkTravaux(elem))
                    } else {
                      dispatch(uncheckTravaux(elem))
                    }
                  }
                }}
                handleValidate={() => dispatch(postSaContributeurs({
                  saId: idSA,
                  user: addContributorSa.utilisateur,
                  functionId: addContributorSa.fonction?.id,
                  complexesId: addContributorSa.batiments_voyageurs?.map(bv => bv.id).filter(s => s) as string[] || [],
                  lignesId: addContributorSa.lignes?.map(ligne => ligne.id).filter(s => s) as string[] || [],
                  travauxId: addContributorSa.travaux?.map(t => t.id).filter(s => s) as string[] || [],
                  userMeId: account.id,
                  selectedContributor,
                }))}
              />
            </div>
            <div className="d-flex contributor-panel">
              <ContributorsList items={contributeurs} idSA={idSA} />
            </div>
          </div>
        )
      }

      <ModalError
        onModal={modalErrContributor}
        titleOption={terms.Settings.contributor.error.errorAddContributor.title2}
        content={`${errors?.data[0]}.`}
        handleClose={() => dispatch(closeModalErrContributor())}
      />
      <ModalPatchContributors />
      <ModalConfirmation
        onModal={modalConfirmation}
        handleConfirmClick={handleDelete}
        content1={terms.Settings.contributor.modalConfirmation.content1}
        content2={terms.Settings.contributor.modalConfirmation.content2}
        handleClose={() => dispatch(closeModalConfirmation())}
      />

      <CustomSnackbar
        message={terms.Settings.contributor.snackbarMessage.contributorAdded}
        displaySnackbar={displaySnackbarAdd}
        handleClose={() => dispatch(hideSnackbarAdd())}
      />
      <CustomSnackbar
        message={terms.Settings.contributor.snackbarMessage.contributorDeleted}
        displaySnackbar={displaySnackbarDelete}
        handleClose={() => dispatch(hideSnackbarDelete())}
      />
      <CustomSnackbar
        message={terms.Settings.contributor.snackbarMessage.contributorUpdated}
        displaySnackbar={displaySnackbarUpdate}
        handleClose={() => dispatch(hideSnackbarUpdate())}
      />
    </>
  )
}

export default Contributors
