import { Box } from '@mui/material'
import terms from 'common/terms'
import CotationPicker from 'components/CotationPicker/CotationPicker'
import {
  ChangeEvent, ReactElement, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Entity } from 'reducers/Admin/Entity/types'
import {
  openAddModal, resetPossibleAnimators, setContributionToDeleteId, setNewContribution, setPatchContribution,
  setSelectedContribution, resetNewContribution,
} from 'reducers/Contributions/contributions.reducer'
import { getSaPossibleAnimators } from 'reducers/Contributions/contributions.thunk'
import { Contribution, PostContribution } from 'reducers/Contributions/types'
import { ComposantObject, ObjectFerTab } from 'reducers/SA/types'
import { UserMeRoles, UserRole, UserSimple } from 'reducers/Users/types'
import { RootState } from 'Store'
import AnimatorToggle from './editor/AnimatorToggle'
import ContributionDuping from './editor/ContributionDuping'
import ContributionImpersonate from './editor/ContributionImpersonate'
import ContributionVisibility from './editor/ContributionVisibility'
import EditorActions from './editor/EditorActions'
import EditorFileUpload from './editor/EditorFileUpload'
import EditorTextField from './editor/EditorTextField'
import ContributionTransfer from './editor/ContributionTransfer/ContributionTransfer'

interface ContributionEditorProps {
  activeJalon: number | null,
  activeComponent: ComposantObject | null
  selectedContribution: Contribution | null,
  newContribution: PostContribution | null,
  editable: boolean,
  entitiesVisibility: Entity[],
  activeSa: UserMeRoles | null,
  possibleAnimators: UserSimple[],
  activeObjFer: ObjectFerTab | null,
}

const parseFileName = (url: string | null):string | null => {
  const splitURL = url?.split('%3D')[1] || null
  return splitURL?.split('&')[0] || null
}

const ContributionEditor = ({
  activeJalon, activeComponent, selectedContribution, newContribution, editable, entitiesVisibility, activeSa,
  possibleAnimators, activeObjFer,
}: ContributionEditorProps):ReactElement => {
  const dispatch = useDispatch()
  const { account } = useSelector((state: RootState) => state.user)
  const [analysis, setAnalysis] = useState('')
  const [proposition, setProposition] = useState('')
  const [cotation, setCotation] = useState<string | null>(null)
  const [file, setFile] = useState<string | ArrayBuffer | null | undefined>(undefined)
  const [fileName, setFileName] = useState<string | null>(null)
  const [fileURL, setFileURL] = useState<string | null>(null)
  const [fileDupeURL, setDupeFileURL] = useState<string | null>(null)
  const [visibility, setVisibility] = useState<string[]>([])
  const [isGlobal, setIsGlobal] = useState<boolean | null>(null)
  const [author, setAuthor] = useState<{cerbereId: string, entityId: string} | null>(null)
  const [duping, setDuping] = useState<string[]>([])
  const [transfer, setTransfer] = useState<string[]>([])

  const defaultAxesId = activeObjFer?.axes.find(axe => axe.id === activeSa?.id)?.id

  const possibleAxes = activeObjFer?.axes.filter(a => a.libelle.includes(activeSa?.annee.toString(10) || '')) || []
  useEffect(() => {
    if (defaultAxesId) setTransfer([defaultAxesId])
  }, [defaultAxesId])

  const isAnimator = (withAdmin: boolean) => !!activeSa?.roles.some(
    role => [
      ...(withAdmin ? [UserRole.admin] : []),
      UserRole.animator,
      ...(activeObjFer?.axes.length ? [] : [UserRole.animatorAxe]),
    ].includes(role),
  )

  const isAxe = activeSa?.eic.axe

  const isAnimatorSelectedContribution = [
    'Administrateur',
    'Animateur',
    ...(activeObjFer && !activeObjFer?.axes.length ? ['Animateur d\'Axe'] : []),
  ].includes(selectedContribution?.role as UserRole)

  const authorCanDuplicate = author === null || author.cerbereId === account.id

  const handleChangeAnalysis = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    if (editable) { setAnalysis(evt.target.value) }
  }
  const handleChangeProposition = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    if (editable) { setProposition(evt.target.value) }
  }
  const handlePickCotation = (rating: string) => () => {
    if (editable) { setCotation(rating) }
  }

  const handleAddContribution = () => {
    dispatch(setNewContribution({
      auteur: author ? author.cerbereId : account.id,
      analyse: analysis,
      proposition,
      file,
      file_name: fileName,
      cotation: isGlobal !== false ? cotation : null,
      visibilite: visibility,
      ...(isGlobal !== null && { is_global: isGlobal }),
      dedoublement: duping,
      ...(fileDupeURL && { file_url: fileDupeURL }),
      axes: possibleAxes.filter(axe => transfer.includes(axe.id)),
    }))
    dispatch(openAddModal())
  }

  const cotationToPatch = () => {
    if (isAnimatorSelectedContribution) {
      return isGlobal !== false ? cotation : null
    }
    return cotation
  }

  const handlePatchContribution = () => {
    dispatch(setPatchContribution({
      analyse: analysis,
      proposition,
      ...((file !== undefined && file !== selectedContribution?.file) && { file }),
      ...((file !== undefined && file !== selectedContribution?.file) !== undefined && { file_name: fileName }),
      cotation: cotationToPatch(),
      visibilite: visibility,
      axes: possibleAxes.filter(axe => transfer.includes(axe.id)),
    }))
  }

  const handleCancelButton = () => {
    dispatch(setSelectedContribution(null))
    dispatch(resetNewContribution())
  }

  const handleDeleteContribution = () => {
    if (selectedContribution) {
      dispatch(setContributionToDeleteId(selectedContribution.id))
    }
  }

  const isAnimatorContribution = (): boolean => (
    author === null ? isAnimator(false)
      : activeSa?.eic.animateurs.some(user => user.cerbere_id === author.cerbereId) || false
  )

  const disableAddButton = (): boolean => (
    !editable
    || (analysis === '' && proposition === '' && activeJalon === 0)
    || (activeJalon !== null && activeJalon > 0 && (cotation === null && isGlobal !== false))
  )

  const getDefaultEntityId = (): string | undefined => {
    if (author && author.entityId !== '') return author.entityId
    if (isAnimator(true)) return undefined
    if (activeJalon === 0 && activeSa?.roles.includes(UserRole.decider)) return activeSa.entite
    if (activeJalon && activeJalon >= 0 && activeSa?.roles.includes(UserRole.contributor)) return activeSa.entite
    return undefined
  }

  useEffect(() => {
    const contrib = newContribution || selectedContribution
    setAnalysis(contrib?.analyse || '')
    setProposition(contrib?.proposition || '')
    setCotation(contrib?.cotation || null)
    setVisibility(contrib?.visibilite
      || [...(getDefaultEntityId() !== undefined ? [getDefaultEntityId() as string] : [])])
    setDuping(duping || [])
    setTransfer(contrib?.axes.map(i => i.id) || [])

    if (newContribution) {
      setFile(newContribution.file || newContribution.file_url)
      setFileName(newContribution.file_name ? parseFileName(newContribution.file_name) : null)
      setFileURL(newContribution.file_url || null)
      setIsGlobal(newContribution.is_global || null)
      setDupeFileURL(newContribution.file_url || null)
      setAuthor(newContribution.auteur ? { cerbereId: newContribution.auteur, entityId: '' } : null)

      if (defaultAxesId) {
        setTransfer([defaultAxesId])
      } else {
        setTransfer(newContribution.axes.map(axe => axe.id))
      }
    } else {
      setFile(selectedContribution?.file)
      setFileName(parseFileName(selectedContribution?.file || null))
      setFileURL(selectedContribution?.file || null)
      setDupeFileURL(null)
      setIsGlobal(selectedContribution?.is_global || null)
      setAuthor(null)
      setTransfer(selectedContribution?.axes.map(axe => axe.id) || [])
    }
  }, [selectedContribution, newContribution])

  useEffect(() => {
    setAnalysis('')
    setProposition('')
    setCotation(null)
    setVisibility([])
    if (isAnimator(true)) {
      setIsGlobal(true)
    } else {
      setIsGlobal(null)
    }
    setAuthor(null)
    setDuping([])
    if (defaultAxesId) {
      setTransfer([defaultAxesId])
    } else {
      setTransfer([])
    }
  }, [activeComponent])

  useEffect(() => {
    if (isAnimator(true)) {
      setIsGlobal(selectedContribution?.is_global === undefined ? true : selectedContribution.is_global)
    }
  }, [activeSa, selectedContribution])

  useEffect(() => {
    if (!selectedContribution && !newContribution) {
      if (activeSa?.eic.animateurs.some(user => user.cerbere_id === author?.cerbereId)
      || (!author && isAnimator(true))) {
        setIsGlobal(true)
      } else {
        setIsGlobal(null)
      }
    }
  }, [author, activeSa])

  useEffect(() => {
    if (activeSa) {
      if (activeSa.roles.includes(UserRole.admin)) {
        dispatch(getSaPossibleAnimators(activeSa.eic.animateurs.map(a => a.cerbere_id)))
      } else {
        dispatch(resetPossibleAnimators())
      }
    }
  }, [activeSa])

  useEffect(() => {
    if (!selectedContribution && !newContribution) {
      if (isGlobal === true && entitiesVisibility) {
        setVisibility(entitiesVisibility.map(e => e.id || ''))
      } else if (isGlobal === false) {
        setVisibility([])
      }
    }
  }, [entitiesVisibility, isGlobal])

  useEffect(() => {
    if (!selectedContribution && !newContribution) {
      setDuping([])
      setVisibility([...(getDefaultEntityId() !== undefined ? [getDefaultEntityId() as string] : [])])
    }
  }, [author])

  const handleAddPdf = (evt: ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader()
    const { files } = evt.currentTarget
    if (files) {
      fileReader.onload = fileLoadEvent => {
        setFile(fileLoadEvent.target?.result)
        setFileName(files[0].name)
        setFileURL(URL.createObjectURL((files || [])[0]))
      }
      fileReader.readAsDataURL((files || [])[0])
    }
  }

  const handleDeletePdf = () => {
    setFile(null)
    setFileName(null)
    setFileURL(null)
  }

  const displayAnimatorToggle = isAnimator(true)
    && (((!author && !selectedContribution) || (selectedContribution?.auteur?.id === account?.id)) || [
      'Administrateur',
      'Animateur',
      ...(activeObjFer && !activeObjFer?.axes.length ? ['Animateur d\'Axe'] : []),
    ].includes(selectedContribution?.role as UserRole)
    || (!selectedContribution
      && author
      && !(activeObjFer?.borrowed
        && activeSa?.eic.animateurs.some(user => user.cerbere_id === author?.cerbereId)
        && activeSa.eic.axe)
      && possibleAnimators.find(a => a.id === author.cerbereId) !== undefined)
    )

  const shoulddisplayCotation = () => {
    if (activeJalon) {
      if (selectedContribution) {
        if (!isAnimatorSelectedContribution) {
          return true
        }
        if (isGlobal === true) {
          return true
        }
        return false
      }
      if (isGlobal !== false) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    const isAnimatorAuthor = possibleAnimators.find(a => a.id === author?.cerbereId)
    if (activeSa?.roles.includes(UserRole.admin) && entitiesVisibility
    && ((isAnimatorAuthor !== undefined && isGlobal === true)
  || (!author && isGlobal === true))
    ) {
      setVisibility(entitiesVisibility.map(e => e.id || ''))
    }
  }, [author])

  useEffect(() => {
    const contrib = newContribution || selectedContribution
    if (isGlobal) {
      setVisibility(entitiesVisibility.map(e => e.id || '') || [])
    } else {
      setVisibility(contrib?.visibilite || [
        ...(getDefaultEntityId() !== undefined ? [getDefaultEntityId() as string] : []),
      ])
    }
  }, [isGlobal, selectedContribution, newContribution])

  return (
    <Box
      className="contribution-editor"
      component="form"
      noValidate
    >
      <div className="mb-3 d-flex flex-column">
        { selectedContribution === null
        && activeSa?.roles.some(role => [
          UserRole.admin,
          UserRole.animator,
          ...(activeObjFer?.axes.length ? [] : [UserRole.animatorAxe]),
        ].includes(role)) && (
        <ContributionImpersonate
          possibleContributors={activeComponent?.contributeurs || []}
          possibleAnimators={possibleAnimators}
          value={author}
          setValue={setAuthor}
        />
        )}
        { displayAnimatorToggle && (
          <AnimatorToggle isGlobal={isGlobal} setIsGlobal={setIsGlobal} selectedContribution={selectedContribution} />
        )}
        <EditorTextField
          disabled={!editable}
          label={terms.Contributions.editor.analysis}
          placeholder={terms.Contributions.editor.analysisPlaceholder}
          value={analysis}
          handleChange={handleChangeAnalysis}
          row={3}
        />
        <EditorFileUpload
          editable={editable}
          selectedContribution={selectedContribution}
          fileURL={fileURL}
          fileName={fileName}
          handleAddPdf={handleAddPdf}
          handleDeletePdf={handleDeletePdf}
        />
        <EditorTextField
          disabled={!editable}
          label={terms.Contributions.editor.proposition}
          placeholder={terms.Contributions.editor.propositionPlaceholder}
          value={proposition}
          handleChange={handleChangeProposition}
          row={3}
        />
        <ContributionVisibility
          disabled={!editable || (!!isGlobal && isAnimatorContribution()) || (!!isGlobal && isAnimator(true))}
          possibleEntities={entitiesVisibility}
          visibility={visibility}
          handleSelectEntity={setVisibility}
          defaultEntityId={getDefaultEntityId()}
          isGlobal={!!isGlobal}
        />
        <ContributionTransfer
          disabled={!editable && !activeSa?.roles.includes(UserRole.animatorAxe)}
          possibleAxes={possibleAxes}
          transfer={transfer}
          handleSelectAxes={setTransfer}
          isAxe={!!isAxe}
        />
        {editable && authorCanDuplicate && activeObjFer !== null && selectedContribution === null && (
          <ContributionDuping setDuping={setDuping} duping={duping} activeObjFer={activeObjFer} />
        )}
        {shoulddisplayCotation() && (
          <CotationPicker disabled={!editable} selectedCotation={cotation} handlePickCotation={handlePickCotation} />
        )}
        <EditorActions
          hasSelectedContribution={selectedContribution !== null}
          editable={editable}
          addButtonDisabled={disableAddButton()}
          handleUnselect={handleCancelButton}
          handleDeleteContribution={handleDeleteContribution}
          handleAddContribution={handleAddContribution}
          handlePatchContribution={handlePatchContribution}
          canModifyAxeHabilitations={
            !!activeSa?.roles.includes(UserRole.animatorAxe) && !!activeObjFer && (activeObjFer?.axes.length > 0)
          }
        />
      </div>
    </Box>
  )
}

export default ContributionEditor
