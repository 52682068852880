import {
  Box, Modal,
} from '@mui/material'
import { cotationInfo } from 'common/cotation'
import terms from 'common/terms'
import ModalCloseButton from 'components/Modal/ModalCloseButton/ModalCloseButton'
import { colorTile } from 'pages/ActionPlan/utils'
import { typeObject } from 'pages/Suivi/utils'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateComposantCurJalon } from 'reducers/ActionPlan/actionPlan.reducer'
import { postComposantsTodoCurJalon } from 'reducers/ActionPlan/actionPlan.thunk'
import { ComposantAction } from 'reducers/ActionPlan/types'
import { RootState } from 'Store'
import { PrimaryBtn } from 'themes/theme'
import './composantTodoEdit.scss'

interface ComposantTodoEditProps {
  onModal: boolean,
  closeModal: () => void,
  objectFerId: string | null,
  objectFerType: string | null,
}

const ComposantTodoEdit = ({
  onModal,
  closeModal,
  objectFerId,
  objectFerType,
}: ComposantTodoEditProps): ReactElement => {
  const dispatch = useDispatch()
  const { composantsCurJalon } = useSelector((state: RootState) => state.actionPlan)
  const { idSA } = useSelector((state: RootState) => state.app)

  const clickUpdateComposant = (id: string) => {
    let composantToUpdate = composantsCurJalon?.find(composant => composant.id === id)
    if (composantToUpdate) {
      composantToUpdate = {
        ...composantToUpdate,
        displayed: !composantToUpdate.displayed,
      }
    }
    const listUpdated = composantsCurJalon?.map((composant: ComposantAction) => (
      (composant.id === id) ? composantToUpdate : composant
    ))
    dispatch(updateComposantCurJalon(listUpdated))
  }

  const handleClickValidate = () => {
    if (objectFerId && objectFerType && composantsCurJalon?.length) {
      dispatch(postComposantsTodoCurJalon({
        saId: idSA,
        objectId: objectFerId,
        type: typeObject(objectFerType),
        composants: composantsCurJalon.map(composant => ({
          id: composant.id,
          displayed: composant.displayed,
        })),
      }))
    }
    closeModal()
  }

  return (
    <Modal
      open={onModal}
      onClose={closeModal}
      className="composantTodoEdit-modal"
    >
      <Box
        className="composantTodoEdit"
      >
        <div className="composantTodoEdit-first">
          <ModalCloseButton handleClose={closeModal} className="close-modal" />
          <div className="composantTodoEdit-title">
            {terms.actionPlan.modalComposant.title}
          </div>
          <div className="composantTodoEdit-body-container">
            <div className="composantTodoEdit-body">
              {composantsCurJalon?.map(composant => (
                <button
                  type="button"
                  onClick={() => clickUpdateComposant(composant.id)}
                  disabled={composant.cotation === 'I' || composant.has_any_action || !composant.cotation}
                  key={composant.id}
                  className="composantTodoEdit-body-composant"
                  style={{
                    cursor: composant.cotation === 'I' || composant.has_any_action || !composant.cotation
                      ? 'not-allowed'
                      : 'pointer',
                    backgroundColor: colorTile(
                      composant.displayed, composant.cotation, composant.has_any_action,
                    ).bgColor,
                    color: colorTile(composant.displayed, composant.cotation, composant.has_any_action).text,
                  }}
                >
                  <div>
                    <div className="composantTodoEdit-body-composant-header">
                      <div className="composantTodoEdit-body-composant-header-id">
                        {composant.composant.id}
                      </div>
                      <div className="composantTodoEdit-body-composant-header-cotation">
                        <div
                          style={{ background: cotationInfo(composant.cotation)?.color }}
                          className="composantTodoEdit-body-composant-header-cotation-item"
                        >
                          {composant.cotation}
                        </div>
                      </div>
                    </div>
                    <div className="composantTodoEdit-body-composant-title">
                      {composant.composant.libelle}
                    </div>
                  </div>
                  {
                    !composant.cotation && (
                      <div className="composantTodoEdit-body-composant-info">
                        (Composant en attente de cotation)
                      </div>
                    )
                  }
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="composantTodoEdit-button-confirmation">
          <PrimaryBtn
            onClick={handleClickValidate}
          >
            {terms.actionPlan.modalComposant.buttonConfirm}
          </PrimaryBtn>
        </div>
      </Box>
    </Modal>
  )
}

export default ComposantTodoEdit
